const OSS = require('ali-oss');

export default {
  client() {
    const clinet = new OSS({
      region: 'oss-cn-hangzhou',
      accessKeyId: process.env.VUE_APP_ACCESSKEYID,
      accessKeySecret: process.env.VUE_APP_ACCESSKEY_SECRET,
      bucket: process.env.VUE_APP_BUCKET,
    });
    return clinet;
  },
};
